<template>
  <div>
    <!-- Show a infinite loading spinner -->
    <v-progress-circular indeterminate color="primary" size="100" class="mb-5" />
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('alert/add', {
      text: 'Vous êtes déconnecté',
      color: 'success'
    })
    this.$store.dispatch('auth/logout')
    this.$router.push('/login')
  }
}
</script>
